import React from 'react';
import '../styles/tech.css';

export default function TechList({ techCommonUse, techHaveUsed }) {
  const commonUse = techCommonUse.map(l => (
    <li css={styles.item} key={l.label}>
      {l.label}
    </li>
  ));
  const haveUsed = techHaveUsed.map(l => (
    <li css={styles.item} key={l.label}>
      {l.label}
    </li>
  ));
  return (
    <div>
      <label>Tech I use...a lot</label>
      <ul className={styles.items}>{commonUse}</ul>
      <label>Tech I've got my hands dirty with:</label>
      <ul className={styles.items}>{haveUsed}</ul>
    </div>
  );
}

/*
export default function TechList({ links }) {
  const items = links.map(l => (
    <div css={styles.item} key={l.logo}>
      <a css={styles.link} href={l.url}>
        {l.label}
      </a>
      <img src={l.logo} css={styles.img} alt={l.logo} />
    </div>
  ));
  return <div className={styles.items}>{items}</div>;
}
*/
const styles = {
  root: {
    '& + &': {
      marginTop: '0.2rem'
    }
  },

  items: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  item: {
    flex: '1 0 25%',
    padding: '10px'
  },

  link: {
    textDecoration: 'none'
  },

  img: {
    width: '50px',
    height: '50px'
  }
};
