import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Profile from '../components/Profile';
import Social from '../components/Social';
import TechList from '../components/TechList';

function getAboutData(data) {
  const edge = data.allYamlYaml.edges.find(e => e.node.about);
  return edge.node.about;
}

export default function AboutPage({ data }) {
  const { profile, social, techCommonUse, techHaveUsed } = getAboutData(data);
  return (
    <Layout>
      <SEO title="About" />
      <h1>About</h1>
      <div css={styles.social}>
        <Social links={social} />
      </div>
      <div css={styles.profile}>
        <Profile title={profile.title} text={profile.text} />
      </div>
      <div css={styles.profile}>
        <TechList techCommonUse={techCommonUse} techHaveUsed={techHaveUsed} />
      </div>
    </Layout>
  );
}

const styles = {
  social: {
    marginTop: '1rem'
  },

  profile: {
    marginTop: '3rem'
  }
};

export const pageQuery = graphql`
  query {
    allYamlYaml {
      edges {
        node {
          about {
            profile {
              title
              text
            }
            social {
              label
              url
            }
            links {
              label
              logo
            }
            techCommonUse {
              label
            }
            techHaveUsed {
              label
            }
          }
        }
      }
    }
  }
`;
